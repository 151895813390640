<template>
  <div class="container-fluid">
    <!-- {{listaAgrupada.map(m=>m.lista.map(e=>e.razao_social))}} -->
    <barra-crud :busca="false"></barra-crud>
    <div class="row">
      <div class="col-12 col-sm-6 text-center">
        <select-especialidade
          :value="filtros.especialidade"
          @especialidadeComplete="
            filtros.especialidade = $event ? $event.id : null
          "
        />
      </div>
      <div class="col-12 col-sm-3"></div>

      <div class="col-12 col-sm-3">
        <b-form-group label="Buscar Nome">
          <b-input v-model="filtros.nome" />
        </b-form-group>
      </div>
    </div>
    <div class="text-center p-4" v-if="filtros.especialidade === ''">
      <h3>Selecione uma especialidade para visualizar os médicos.</h3>
    </div>
    <div class="text-center p-4" v-if="loading">
      <b-spinner /><br />
      aguarde...
    </div>

    <div class="row">
      <div
        class="col-12 col-lg-6 p-3"
        v-for="med in medicos"
        :key="`med_${med.id}_${Math.random(12121)}`"
      >
        <div class="cardmed cursor" @click="openMed(med)">
          <div class="titulo">
            {{ med.razao_social }}
          </div>
          <div class="row p-3">
            <div class="col-12 col-md-6 text-left ">
              <b><b-icon-telephone /> Telefone(s)</b>
              <hr />
              <small v-if="med.telefones.length == 0">
                Nenhum Telefone
              </small>
              <div class=" d-flex justify-content-start flex-wrap">
                <div
                  v-for="telefone in med.telefones"
                  :key="`${telefone}_${med.telefones.indexOf(telefone)}`"
                  class="pr-3 pl-3"
                  style="border-right:1px solid #ccc"
                >
                  <span>
                    <small
                      ><b>{{
                        telefone.contato ? telefone.contato : ""
                      }}</b></small
                    >
                    <p style="margin: 0">{{ telefone.telefone }}</p>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-6 text-left">
              <b><i class="fa fa-stethoscope" /> Especialidade(s)</b>
              <hr />
              <small v-if="med.especialidadesOBJ.length == 0">
                Nenhuma Especialidade
              </small>
              <div class="list">
                <ul
                  v-for="especialidade in med.especialidadesOBJ"
                  :key="especialidade.especialidade"
                >
                  <li>
                    {{ especialidade.especialidade }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-12  text-left mt-5">
              <b><i class="fa fa-stethoscope" /> Endereço(s)</b>
              <hr />
              <small v-if="med.enderecos.length == 0">
                Nenhuma Endereço
              </small>
              <div
                v-for="endereco in med.enderecos"
                :key="`${endereco.cep}_${endereco.numero}`"
              >
                <small>
                  <b v-if="endereco.consultorio == 1">CONSULTÓRIO - </b>
                  {{ endereco.logradouro }}, n° {{ endereco.numero }} -
                  {{ endereco.bairro }} | {{ endereco.cidade }} -
                  {{ endereco.uf }}
                </small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-med" size="lg" hide-footer title-class="titulo">
      <template #modal-title class="titulo">
        <i class="fa fa-user-md" /> {{ medicoSelecionado.razao_social }}
      </template>

      <div class="row">
        <div class="col-12 col-md-3">
          <b>CPF / CNPJ:</b><br /><small>{{ medicoSelecionado.cpf }}</small>
        </div>
        <div class="col-12 col-md-3" style="border-left:1px solid #ccc">
          <b>Telefones:</b><br />
          <small>
            <ul>
              <li
                v-for="tel in medicoSelecionado.telefones"
                :key="`tels_${tel.telefone}`"
              >
                {{ tel.telefone }}
              </li>
            </ul>
          </small>
        </div>
        <div class="col-12 col-md-6" style="border-left:1px solid #ccc">
          <b>Endereços:</b><br />
          <small>
            <ul>
              <li
                v-for="endereco in medicoSelecionado.enderecos"
                :key="`tels_${endereco.logradouro + endereco.numero}`"
              >
                <small>
                  <b v-if="endereco.consultorio == 1">CONSULTÓRIO - </b>
                  {{ endereco.logradouro }}, n° {{ endereco.numero }} -
                  {{ endereco.bairro }} | {{ endereco.cidade }} -
                  {{ endereco.uf }}
                </small>
              </li>
            </ul>
          </small>
        </div>
        <div class="col-12">
          <hr />
        </div>
        <div class="col-12">
          <b-tabs>
            <b-tab title="Exames / Consultas">
              <b-input
                placeholder="Buscar Exame/Consulta"
                class="mb-2"
                v-model="buscarExame"
              />
              <b-table
                :items="medicoSelecionado.examesFiltrados"
                :fields="[
                  { key: 'nome', label: 'Exame/Consulta' },
                  { key: 'valor_venda', label: 'Valor Venda' },
                ]"
                hover
                small
                striped
                style="font-size:10px"
                :filter="buscarExame"
              >
                <template #cell(nome)="row">
                  <div class="">
                    {{ row.item.nome }}<br />
                    <small>
                      {{ row.item.descricao }}
                    </small>
                  </div>
                </template>
                <template #cell(valor_venda)="row">
                  <div class="text-center">
                    {{ row.item.valor_venda | currencyWithMask }}
                  </div>
                </template>
              </b-table>
            </b-tab>
            <b-tab title="Próximas Agendas">
              <b-table
                selectable
                select-mode="single"
                @row-selected="openAgenda"
                ref="tbAgenda"
                hover
                :items="medicoSelecionado.agendas"
                :fields="[
                  {
                    key: 'data',
                    label: 'Data',
                    sortable: true,
                    class: 'text-center',
                  },
                  {
                    key: 'clinic',
                    label: 'Local',
                    sortable: true,
                    class: 'text-center',
                  },
                  {
                    key: 'hora_inicio',
                    label: 'Início ás',
                    sortable: true,
                    class: 'text-center',
                  },
                  {
                    key: 'cons',
                    label: 'Vagas / Disp.',
                    sortable: true,
                    class: 'text-center',
                  },
                ]"
              >
                <template #cell(data)="row">
                  {{ row.item.data | moment("DD/MM/YYYY") }}
                </template>
                <template #cell(cons)="row">
                  {{ row.item.agendados }} / {{ row.item.consulta_qtd }}
                </template>
              </b-table>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-agenda"
      modal-class="modalMaior"
      size="xl"
      hide-footer
      style="max-width: 90%"
      title="Agenda"
   
    >
      <agenda-horarios
        v-if="agendaSelecionada"
        :idAgenda="agendaSelecionada.id"
      />
    </b-modal>
  </div>
</template>

<script>
import BarraCrud from "@/components/common/BarraCrud.vue";
// import CorpoClinicoInfo from "../../../components/Entidades/CorpoClinico/CorpoClinicoInfo.vue";
import EntidadesLib from "../../../libs/EntidadesLib";
import SelectEspecialidade from "@/components/common/SelectEspecialidade.vue";
import AgendaHorarios from "../../../components/Agendas/AgendaHorarios.vue";
export default {
  components: {
    BarraCrud,
    SelectEspecialidade,
    AgendaHorarios,
    // CorpoClinicoInfo
  },
  data() {
    return {
      medicos: [],
      medicosRaw: [],
      grupos: [],
      filtros: {
        especialidade: "",
        nome: "",
      },
      medicoSelecionado: {},
      loading: true,
      buscarExame: "",
      agendaSelecionada: null,
    };
  },
  watch: {
    "filtros.especialidade": {
      deep: true,
      handler() {
        this.carregarMedicos();
      },
    },
    "filtros.nome": function() {
      this.filtrar();
    },
  },
  methods: {
    openAgenda(item) {
      // console.log(item);
      if (item && item.length > 0) {
        this.agendaSelecionada = item[0];
        this.$bvModal.show("modal-agenda");
        this.$refs.tbAgenda.clearSelected();

      }
    },
    openMed(med) {
      if (med.exames) {
        med.examesFiltrados = med.exames.sort((a, b) => {
          return a.nome > b.nome ? 1 : -1;
        });
      }
      this.medicoSelecionado = med;
      this.$bvModal.show("modal-med");
    },
    async carregarMedicos() {
      this.medicosRaw = [];
      this.loading = true;
      if (this.filtros.especialidade) {
        this.medicosRaw = (
          await EntidadesLib.get(
            "medico",
            {
              pagina_atual: 1,
              registros_por_pagina: 99999,
            },
            "",
            {
              especialidade_id: this.filtros.especialidade,
              withAgendasFuturas: true,
            }
          )
        )?.entidades
          .sort((a, b) => {
            return a.razao_social > b.razao_social ? 1 : -1;
          })
          .filter((x) => x.status === 1)
          .filter((x) => x.exames && x.exames.length > 0);
        console.log("ress", this.medicosRaw);
        this.filtrar();
      }
      console.log("medicos", this.medicos);
      this.loading = false;
    },
    filtrar() {
      this.medicos = this.medicosRaw.filter(
        (x) =>
          !this.filtros.nome ||
          this.filtros.nome == "" ||
          x.razao_social
            .toLowerCase()
            .indexOf(this.filtros.nome.toLowerCase()) >= 0
      );
    },
    openInfo(medico) {
      this.medicoSelecionado = Object.assign({}, medico);
      this.$bvModal.show("modal-info");
    },
    close() {
      this.$bvModal.hide("modal-info");
    },
  },
  async mounted() {
    await this.carregarMedicos();
  },
  computed: {
    listaAgrupada() {
      let grupos = [];
      if (this.medicos && this.medicos.length > 0) {
        this.medicos.map((med) => {
          if (med && med.especialidadesOBJ) {
            med.especialidadesOBJ.forEach((espec) => {
              if (!grupos.some((g) => g.grupo == espec.especialidade)) {
                grupos.push({ grupo: espec.especialidade, lista: [] });
              }
            });
            for (const grupo of grupos) {
              if (
                med.especialidadesOBJ.some(
                  (e) => e.especialidade == grupo.grupo
                ) &&
                !grupo.lista.some((x) => x.id == med.id)
              ) {
                //  console.log(med.razao_social, med.especialidadesOBJ);
                grupo.lista.push(med);
              }
            }
          }
        });

        if (this.filtros.especialidade) {
          grupos = grupos.filter((g) => g.grupo == this.filtros.especialidade);
        }
      }
      // console.log(grupos);
      return grupos;
    },
  },
};
</script>
<style scoped lang="scss">
.cursor {
  cursor: pointer;
  transform: scale(0.95);

  &:hover {
    border: 1px solid #eee;
    box-shadow: 0px 0px 10px #00000050;
    transform: scale(1.05);
  }
}
.cardmed {
  width: 100%;
  min-height: 300px;
  border-radius: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  transition: 0.3s all ease;
}
.titulo {
  width: 100%;
  padding: 20px;
  text-align: center;
  color: white;
  font-size: 22px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  font-weight: bold;
  background: linear-gradient(-60deg, #a01616 0%, #f43f3f 100%);
}
</style>
